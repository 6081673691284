import { get, has, isArray, isFunction, isNil, set, sortBy } from 'lodash-es';
import moment from 'moment';

import { Type } from '@angular/core';

import { Constructable, PrimitiveConstructable } from '@bp/shared/models/core';
import { Enumeration } from '@bp/shared/models/core/enum';
import { Dictionary } from '@bp/shared/typings';
import { hasOwnProperty, isEmpty, isExtensionOf, isPresent } from '@bp/shared/utilities/core';

import { ClassMetadata } from './class-metadata';
import { PropertyMapper, PropertyMapperFunction, PropertyMetadata } from './property-metadata';

export abstract class MetadataEntity extends Constructable {

	private static readonly _metadata: ClassMetadata<any>;

	static getClassMetadata<T extends typeof MetadataEntity>(this: T): ClassMetadata<InstanceType<T>> {
		if (has(this, '_metadata'))
			return <any> this._metadata;

		// @ts-expect-error we need to treat metadata as readonly in any way
		return (this._metadata = new ClassMetadata<InstanceType<T>>(this));
	}

	protected static _initClassMetadata<T extends typeof MetadataEntity>(this: T): void {
		this.getClassMetadata();
	}

	static {
		moment.fn.toJSON = function() {
			return <string> <any> this.unix();
		};
	}

	get classMetadata(): ClassMetadata<this> {
		return <any>(<typeof MetadataEntity> this.constructor).getClassMetadata();
	}

	readonly #isMetadataEntityInstance = true;

	protected get _isMetadataEntityInstance(): boolean {
		return #isMetadataEntityInstance in this;
	}

	constructor(protected readonly _instanceDTO?: Dictionary<unknown>) {
		super();

		this.__assertClassMetadataInitialized();

		this.__setPropertiesAttributes();

		if (this._instanceDTO) {
			this.__invokePropertyMappers(
				this.__tryMergeSourceOfDTOIntoInstanceDTO(this._instanceDTO),
			);
		}

		this.__setDefaultPropertiesValues();

		this.__setSymbols();

		this.__reflectAliasValueOntoAliasSourceProperty();

		this.__instanceDTOPropertyAsUnserializable();
	}

	hasPropertyInInstanceDto(property: string): boolean {
		return has(this._instanceDTO, property);
	}

	private __tryMergeSourceOfDTOIntoInstanceDTO(instanceDTO: Dictionary<unknown>): Dictionary<unknown> {
		const sourcesOfDTOMetadata = this.classMetadata
			.values
			.filter(v => v.sourceOfDTO);

		if (isEmpty(sourcesOfDTOMetadata))
			return instanceDTO;

		this.__assertSourceOfDTODecoratorMetadata(sourcesOfDTOMetadata);

		const [ sourceOfDTOMetadata ] = sourcesOfDTOMetadata;

		if (!this.__isFunctionMapper(sourceOfDTOMetadata.mapper!))
			throw new Error(`Only a function mapper is allowed for  ${ sourceOfDTOMetadata.property }`);

		return {
			...instanceDTO,
			...sourceOfDTOMetadata.mapper(
				instanceDTO[sourceOfDTOMetadata.property],
				instanceDTO,
				this,
			),
		};
	}

	private __assertSourceOfDTODecoratorMetadata(sourceOfDTOMetadata: PropertyMetadata[]): void {
		if (sourceOfDTOMetadata.length > 1)
			throw new Error('Only one SourceOfDTO decorator can be declared per class');
	}

	private __invokePropertyMappers(instanceDTO: Dictionary<unknown>): void {
		this.classMetadata
			.values
			.filter(v => !v.sourceOfDTO)
			.forEach(propertyMetadata => this.__isMetadataMapperAndDTOValueValidForMapping(propertyMetadata, instanceDTO)
				? void this.__applyMetadataMapperToDTOValueAndSetToInstance(propertyMetadata, instanceDTO)
				: void this.__setDTOValueAsIsToInstance(propertyMetadata, instanceDTO));
	}

	private __isMetadataMapperAndDTOValueValidForMapping(
		{ mapper, property, aliasForPropertyName }: PropertyMetadata, instanceDTO: Dictionary<unknown>,
	): boolean {
		if (!mapper)
			return false;

		const dtoHasAliasProperty = hasOwnProperty(instanceDTO, property);

		return dtoHasAliasProperty
			? !isNil(instanceDTO[property])
			: !isNil(instanceDTO[aliasForPropertyName!]) || !isNil(instanceDTO[property]);
	}

	private __setDTOValueAsIsToInstance({ property, aliasForPropertyName }: PropertyMetadata, instanceDTO: Dictionary<unknown>): void {
		const dtoHasAliasProperty = hasOwnProperty(instanceDTO, property);

		if (dtoHasAliasProperty)
			set(this, property, get(instanceDTO, property));
		else if (aliasForPropertyName) {

			const dtoAliasSourceValue = get(instanceDTO, aliasForPropertyName);
			const dtoHasAliasSourceProperty = hasOwnProperty(instanceDTO, aliasForPropertyName);

			if (dtoHasAliasSourceProperty && dtoAliasSourceValue)
				set(this, property, dtoAliasSourceValue);
		}
	}

	private __applyMetadataMapperToDTOValueAndSetToInstance(
		{ mapper, property, aliasForPropertyName }: PropertyMetadata,
		instanceDTO: Dictionary<unknown>,
	): void {

		const dtoPropertyValue = hasOwnProperty(instanceDTO, property)
			? get(instanceDTO, property)
			: (aliasForPropertyName ? get(instanceDTO, aliasForPropertyName) : null);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const mappedPropertyValue = this.__invokeInferredMapperBasedOnValueType(dtoPropertyValue, mapper!, property, instanceDTO);

		set(this, property, mappedPropertyValue);
	}

	private __invokeInferredMapperBasedOnValueType(
		dtoPropertyValue: any,
		mapper: PropertyMapper,
		property: string,
		instanceDTO: Dictionary<unknown>,
	): any {
		if (isArray(dtoPropertyValue) && !this.__isFunctionMapper(mapper)) {
			const mappedArray = dtoPropertyValue.filter(isPresent).map(dtoValue => this.__invokeInferredMapper(property, mapper, dtoValue, instanceDTO));

			return this.__isEnumMapper(mapper)
				? sortBy(mappedArray.filter(isPresent), (item: Enumeration) => item.index)
				: mappedArray;
		}

		return this.__invokeInferredMapper(property, mapper, dtoPropertyValue, instanceDTO);
	}

	private __invokeInferredMapper(property: string, mapper: PropertyMapper, dtoPropertyValue: any, instanceDTO: Dictionary<unknown>): any {
		if (this.__isFunctionMapper(mapper))
			return mapper(dtoPropertyValue, instanceDTO, this);

		if (this.__isEnumMapper(mapper))
			return this.__invokeEnumMapper(property, mapper, dtoPropertyValue);

		if (this.__isConstructableMapper(mapper))
			return new mapper(dtoPropertyValue);

		throw new Error('Unsupported metadata entity property mapper');
	}

	private __isFunctionMapper(mapper: PropertyMapper): mapper is PropertyMapperFunction {
		return Object.getPrototypeOf(mapper) === Object.getPrototypeOf(Function);
	}

	private __isEnumMapper(mapper: PropertyMapper): mapper is typeof Enumeration {
		return isExtensionOf(mapper, Enumeration);
	}

	private __isConstructableMapper(mapper: PropertyMapper): mapper is Type<Constructable> {
		return isExtensionOf(mapper, Constructable) || isExtensionOf(mapper, PrimitiveConstructable);
	}

	private __invokeEnumMapper(property: string, enumeration: typeof Enumeration, dtoPropertyValue: any): Enumeration | null {
		if (isNil(dtoPropertyValue) || dtoPropertyValue === '')
			return null;

		const enumValue = enumeration.parse(dtoPropertyValue);

		if (isNil(enumValue))
			console.error(`${ property }: invalid enum value received '${ dtoPropertyValue }'`);

		return enumValue;
	}

	private __setPropertiesAttributes(): void {
		this.classMetadata.values
			.filter(v => v.unserializable)
			.forEach(v => Object.defineProperty(this, v.property, {
				enumerable: false,
				configurable: true,
				writable: true,
			}));
	}

	private __instanceDTOPropertyAsUnserializable(): void {
		Object.defineProperty(this, '_instanceDTO', {
			enumerable: false,
			configurable: false,
			writable: false,
		});
	}

	private __setDefaultPropertiesValues(): void {
		this.classMetadata.values
			.filter(propertyMetadata => propertyMetadata.defaultPropertyValue !== undefined
				&& isNil(get(this, propertyMetadata.property)))
			.forEach(propertyMetadata => set(
				this,
				propertyMetadata.property,
				isFunction(propertyMetadata.defaultPropertyValue) ? propertyMetadata.defaultPropertyValue() : propertyMetadata.defaultPropertyValue,
			));
	}

	private __setSymbols(): void {
		this.classMetadata.values
			.filter(({ symbolize }) => symbolize !== null)
			.forEach(({ property, symbolize }) => set(
				this,
				property,
				Symbol(`${ symbolize }_${ this.constructor.name }_${ property }`),
			));
	}

	private __reflectAliasValueOntoAliasSourceProperty(): void {
		this.classMetadata.values
			.filter(({ aliasForPropertyName, serializeAliasSourceProperty }) => !!aliasForPropertyName && serializeAliasSourceProperty)
			.forEach(({ property, aliasForPropertyName }) => Object.defineProperty(
				this,
				aliasForPropertyName!,
				{
					get: () => get(this, property),
					enumerable: true,
				},
			));
	}

	private __assertClassMetadataInitialized(): void {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (!this.classMetadata)
			throw new Error(`${ this.constructor.name }: Class metadata hasn't been initialized. \nProbably that means that the class was instantiated before the static metadata got initialized, for example if a class doesn't have any decorated properties. \nTo fix for this please declare the static block at the top of the class calling the static method explicitly _initClassMetadata()`);
	}

}
