import { pickBy } from 'lodash-es';

import { Dictionary } from '@bp/shared/typings';

import { MetadataEntity } from './metadata-entity';

export class ExtendedMetadataEntity extends MetadataEntity {

	constructor(dto: Dictionary<unknown>) {
		super(dto);

		Object.assign(this, pickBy(this._instanceDTO, (_value, key) => !Object.hasOwn(this, key)));
	}

}
